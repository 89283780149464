import { useEffect, useRef, useState } from "react";
import {
  App,
  Badge,
  Button,
  Col,
  Form,
  Input,
  Popconfirm,
  Row,
  Space,
  Tabs,
} from "antd";
import { Timeseries, Asset } from "@cognite/sdk";
import { ArrowRightOutlined, EditOutlined } from "@ant-design/icons";
import { Dayjs } from "@properate/dayjs";
import {
  Alarm,
  ComparisonTimeseries,
  getSystemCodeFromExternalId,
  SensorInfo,
} from "@properate/common";
import { useHotkeys } from "react-hotkeys-hook";
import { useUser } from "@properate/auth";
import { useTranslations } from "@properate/translations";
import { getSetPointStatus, updateSetPoint, updateTimeseries } from "@/eepApi";
import {
  DEFAULT_MESSAGE_DURATION,
  getAssetById,
  isStringNumeric,
} from "@/utils/helpers";
import { DocumentsPane } from "@/pages/common/SchemaGraph/DocumentsPane";
import { mutateAlarm } from "@/services/alarms";
import { AlarmEventsList, AlarmPane, SchedulePane } from "@/features/alarms";
import { useCogniteClient } from "@/context/CogniteClientContext";
import { useTimeseriesSettings } from "@/services/timeseriesSettings";
import { getStateDescription, parseError } from "../utils";

import { DetailsPane } from "./DetailsPane";
import { MonitorPane } from "./MonitorPane";
import { SetPointsPane } from "./SetPointsPane";
import { GraphPeriod } from "./GraphPeriod";
import { ALARM_TYPE_CONFIG } from "./AlarmUtils";
import { SetPoints } from "./types";
import { ReactComponent as ExternalLink } from "./icons/external-link.svg";
import { Graph } from "./Graph";
import {
  Container,
  DescriptionInput,
  Details,
  DetailsContent,
  GModal,
  GraphController,
  GraphSection,
  Header,
  Minimize,
  MinimizeButton,
} from "./elements";

interface Props {
  timeseriesInfo: SensorInfo;
  setTimeseriesInfo?: Function;
  showSettings?: Function;
  showAlerts?: boolean;
  showSetPoints?: boolean;
  showDocuments?: boolean;
  hide: Function;
  deleteTimeseries?: Function;
  showAlarm?: Alarm & { snapshotId?: string };
  expanded?: boolean;
  showView?: boolean;
  comparisonTimeseriesMin?: ComparisonTimeseries;
  comparisonTimeseriesMax?: ComparisonTimeseries;
  onEditMaxTimeseries?: () => void;
  onEditMinTimeseries?: () => void;
  openRoomInfo?: (id: number) => void;
  editable?: boolean;
  buildingId: number;
  showCreateNoteButtonInAlarmEventsList?: boolean;
  showBehindRoomInfo?: boolean;
}

const getRoomSensorsForType = async (
  timeseriesAsset: Asset,
  relatesdTimesries: Asset[],
) => {
  return relatesdTimesries.filter((r) =>
    timeseriesAsset.labels!.every((label) =>
      r.labels!.some((rlabel) => label.externalId === rlabel.externalId),
    ),
  );
  return [];
};

export const GraphModal = ({
  timeseriesInfo,
  setTimeseriesInfo,
  hide,
  showSettings,
  showAlerts,
  showSetPoints,
  deleteTimeseries,
  showAlarm,
  showDocuments,
  expanded,
  comparisonTimeseriesMin,
  comparisonTimeseriesMax,
  onEditMaxTimeseries,
  onEditMinTimeseries,
  openRoomInfo,
  editable,
  buildingId,
  showCreateNoteButtonInAlarmEventsList,
  showBehindRoomInfo,
}: Props) => {
  const t = useTranslations();
  const { message } = App.useApp();
  const { client } = useCogniteClient();
  const user = useUser();
  const [timeseries, setTimeseries] = useState<Timeseries>();
  const [timeseriesRoomId, setTimeseriesRoomId] = useState<number>();
  const [minimized, setMinimized] = useState(!expanded);
  const [showExternalId, setShowExternalId] = useState(false);
  const [end, setEnd] = useState<Dayjs>();
  const [start, setStart] = useState<Dayjs>();
  const [alarm, setAlarm] = useState<Props["showAlarm"]>(showAlarm);
  const [detailsErrors, setDetailsErrors] = useState<Record<string, string>>(
    {},
  );
  const [alarmErrors, setAlarmErrors] = useState<Record<string, string>>({});
  const [setPoints, setSetPoints] = useState<SetPoints[]>();
  const [relatedSensors, setRelatedSensors] = useState<Asset[]>();
  const [setPointErrors, setSetPointErrors] = useState<Record<string, string>>(
    {},
  );

  const { overrideUnits } = useTimeseriesSettings(buildingId);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [hasIncompleteSubscriber, setHasIncompleteSubscriber] = useState(false);

  const currentAlarm = useRef(alarm);

  useEffect(() => {
    currentAlarm.current = alarm;
  }, [alarm]);

  useEffect(() => {
    const propsToSync = ["compareWithTsMax", "compareWithTsMin"] as const;

    if (!showAlarm || !currentAlarm.current) {
      return;
    }

    for (const prop of propsToSync) {
      if (showAlarm[prop] !== currentAlarm.current![prop]) {
        setAlarm({
          ...currentAlarm.current!,
          [prop]: showAlarm[prop],
        });
      }
    }
  }, [showAlarm]);

  useEffect(() => {
    const get = async () => {
      const relationships = await client.relationships
        .list({
          filter: {
            targetExternalIds: [timeseries!.externalId!],
            labels: {
              containsAll: [{ externalId: "rel_setpt_realval_gen" }],
            },
            confidence: { min: 0.3, max: 1 },
          },
        })
        .autoPagingToArray({ limit: -1 });
      // remove entries with lower confidence
      const rel = relationships
        .filter(
          (r) =>
            !relationships.some(
              (r2) =>
                r2.externalId !== r.externalId &&
                r2.sourceExternalId === r.sourceExternalId &&
                r2.confidence! >= r.confidence!,
            ),
        )
        .sort((a, b) => a.sourceExternalId.localeCompare(b.sourceExternalId));

      if (rel.length > 0) {
        const sourceTimeseries = await client.timeseries.retrieve(
          rel.map((r) => ({ externalId: r.sourceExternalId })),
        );

        const currentStatus = await getSetPointStatus({
          external_ids: rel.map((r) => r.sourceExternalId),
        });

        setSetPoints(
          sourceTimeseries.map((ts) => {
            const status = currentStatus[ts.externalId!];

            return {
              externalId: ts.externalId!,
              name: ts.name!,
              description: ts.description,
              unit:
                (timeseries &&
                  overrideUnits &&
                  overrideUnits[timeseries.externalId!]?.unit) ||
                ts.unit,
              default_value: ts.metadata?.default_value,
              max_value: ts.metadata?.max_value,
              min_value: ts.metadata?.min_value,
              ...status,
              "priority-array": (status?.["priority-array"] || []).map(
                (p: { index: number; value: number | string }) => ({
                  ...p,
                  value: p.value + "",
                  oldValue: p.value + "",
                }),
              ),
              showOverride:
                status?.["priority-array"]?.find((p: any) => p.index === 8) !==
                undefined,
            };
          }),
        );
      }
    };

    if (client && timeseries && showSetPoints) {
      get();
    }
  }, [client, timeseries, showSetPoints, overrideUnits]);

  const showAlarmSubscriptions = showAlarm?.subscriptions;

  type SubscriptionByUser = Record<
    string,
    { email: string[]; phone: string[] }
  >;

  const [subscription, setSubscription] = useState<SubscriptionByUser>({});

  useEffect(() => {
    if (showAlarmSubscriptions) {
      const subscription = showAlarmSubscriptions.reduce<SubscriptionByUser>(
        (acc, cur) => ({
          ...acc,
          [cur.createdBy!]: {
            email: cur.emails || [],
            phone: cur.phones || [],
          },
        }),
        {},
      );

      setSubscription(subscription);
    }
  }, [showAlarmSubscriptions]);

  useEffect(() => {
    const get = async () => {
      if (timeseries) {
        if (timeseries.externalId!.endsWith("RT001_max")) {
          const roomTemperatureMaxId = await client.timeseries.retrieve([
            {
              externalId: timeseries.externalId!.replace(
                "RT001_max",
                "RT001_max_id",
              ),
            },
          ]);
          setTimeseriesRoomId(roomTemperatureMaxId[0].id);
        } else if (timeseries.externalId!.endsWith("RT001_min")) {
          const roomTemperatureMinId = await client.timeseries.retrieve([
            {
              externalId: timeseries.externalId!.replace(
                "RT001_min",
                "RT001_min_id",
              ),
            },
          ]);

          setTimeseriesRoomId(roomTemperatureMinId[0].id);
        } else if (timeseries.externalId!.endsWith("RY001_max")) {
          const roomCO2MaxId = await client.timeseries.retrieve([
            {
              externalId: timeseries.externalId!.replace(
                "RY001_max",
                "RY001_max_id",
              ),
            },
          ]);
          setTimeseriesRoomId(roomCO2MaxId[0].id);
        } else if (timeseries.externalId!.endsWith("RY002_max")) {
          const roomVOCMaxId = await client.timeseries.retrieve([
            {
              externalId: timeseries.externalId!.replace(
                "RY002_max",
                "RY002_max_id",
              ),
            },
          ]);

          setTimeseriesRoomId(roomVOCMaxId[0].id);
        } else if (timeseries.externalId!.endsWith("RH002_norm")) {
          const roomHumidityId = await client.timeseries.retrieve([
            {
              externalId: timeseries.externalId!.replace(
                "RY002_norm",
                "RY002_norm_id",
              ),
            },
          ]);

          setTimeseriesRoomId(roomHumidityId[0].id);
        }
      }
    };

    get();
  }, [timeseries, client]);

  function handleChangeIncompleteSubscriber(value: boolean) {
    setHasIncompleteSubscriber(value);
    if (!value) {
      setAlarmErrors(
        ({ subscriber, ...alarmErrorsCurrent }) => alarmErrorsCurrent,
      );
    }
  }

  const validateDetails = () => {
    const errors: Record<string, string> = {};
    if (alarm && (alarm.name === undefined || alarm.name === "")) {
      errors.name = t("common.timeseries-graph-modal.name-cant-be-empty");
    }
    setDetailsErrors(errors);
    return errors;
  };

  const validateAlarm = () => {
    const errors: Record<string, string> = {};

    if (alarm) {
      if (ALARM_TYPE_CONFIG[alarm.type].showMin && alarm.min === undefined) {
        errors.min = t(
          "common.timeseries-graph-modal.you-should-set-min-value",
        );
      }
      if (ALARM_TYPE_CONFIG[alarm.type].showMax && alarm.max === undefined) {
        errors.max = t(
          "common.timeseries-graph-modal.you-should-set-max-value",
        );
      }
      if (hasIncompleteSubscriber) {
        errors.subscriber = t(
          "common.timeseries-graph-modal.you-must-press-plus",
        );
      }
    }

    setAlarmErrors(errors);
    return errors;
  };

  const validateSetPoints = () => {
    const errors: Record<string, string> = {};
    if (setPoints) {
      setPoints.forEach((setPoint, index) => {
        setPoint["priority-array"]?.forEach((p) => {
          if (p.value && isNaN(+p.value)) {
            errors[`${index}-${p.index}`] = t(
              "common.timeseries-graph-modal.invalid",
            );
          } else if (!p.value && p.index === 16) {
            errors[`${index}-${p.index}`] = t(
              "common.timeseries-graph-modal.lacks-value",
            );
          } else if (
            setPoint.max_value &&
            p.value &&
            +p.value > +setPoint.max_value
          ) {
            errors[`${index}-${p.index}`] = t(
              "common.timeseries-graph-modal.over-max",
              {
                value: setPoint.max_value,
              },
            );
          } else if (
            setPoint.min_value &&
            p.value &&
            +p.value < +setPoint.min_value
          ) {
            errors[`${index}-${p.index}`] = t(
              "common.timeseries-graph-modal.under-min",
              {
                value: setPoint.min_value,
              },
            );
          }
        });
      });
    }
    setSetPointErrors(errors);
    return errors;
  };

  const submit = async () => {
    if (!isSubmitting) {
      setIsSubmitting(true);
      try {
        if (newDescription) {
          setNewDescription(undefined);
        }
        if (alarm) {
          const errors = {
            ...validateDetails(),
            ...validateAlarm(),
          };
          if (Object.keys(errors).length === 0) {
            await mutateAlarm({
              ...alarm,
              max:
                typeof alarm.max === "string" ? Number(alarm.max) : alarm.max,
              min:
                typeof alarm.min === "string" ? Number(alarm.min) : alarm.min,
              subscriptions: Object.entries(subscription).map(
                ([user, subscription]) => ({
                  emails: subscription.email,
                  phones: subscription.phone,
                  createdBy: user,
                }),
              ),
            });
            hide();
          } else {
            console.warn("validation failed", errors);
          }
        } else if (setPoints) {
          const errors = validateSetPoints();
          if (Object.keys(errors).length === 0) {
            const newErrors: Record<string, string> = {};
            loop: for await (const setPoint of setPoints) {
              for await (const priority of setPoint["priority-array"]) {
                try {
                  if (priority.value !== undefined) {
                    if (Number(priority.oldValue) !== Number(priority.value)) {
                      await updateSetPoint({
                        external_id: setPoint.externalId,
                        priority: priority.index,
                        value: parseFloat(priority.value),
                        audit_source: "web",
                      });
                    }
                  } else {
                    // delete
                    await updateSetPoint({
                      external_id: setPoint.externalId,
                      priority: priority.index,
                      audit_source: "web",
                    });
                  }
                } catch (error: any) {
                  console.error(error.message);
                  newErrors[`${setPoints.indexOf(setPoint)}-${0}`] = t(
                    "common.timeseries-graph-modal.update-failed",
                  );
                  break loop;
                }
              }
            }
            if (Object.keys(newErrors).length === 0) {
              hide();
            } else {
              setSetPointErrors(newErrors);
            }
          } else {
            console.warn("validation failed", errors);
          }
        } else {
          hide();
        }
      } finally {
        setIsSubmitting(false);
      }
    }
  };

  useHotkeys("Control+Shift+x", () => setShowExternalId(!showExternalId), [
    showExternalId,
  ]);

  useEffect(() => {
    const get = async () => {
      const ts = await client.timeseries.retrieve([{ id: timeseriesInfo.id }]);
      setTimeseries(ts[0]);
    };
    get();
  }, [client, timeseriesInfo.id]);

  const [name, setName] = useState<string | undefined>();

  const [yAxisSettings, setYAxisSettings] = useState<{
    min?: string;
    max?: string;
  } | null>(null);

  const [editDescription, setEditDescription] = useState(false);
  const [newDescription, setNewDescription] = useState<string>();

  const [roomId, setRoomId] = useState<number | undefined>();

  useEffect(() => {
    const get = async () => {
      setName(undefined);
      setRoomId(undefined);
      if (
        getSystemCodeFromExternalId(timeseries!.externalId!).startsWith("200")
      ) {
        const asset = await getAssetById(client, timeseries!.assetId!);
        const room = await getAssetById(client, asset.parentId!);
        if (room.labels!.some((label) => label.externalId === "room")) {
          setName(`${room.name} ${room.description || ""}`);
          setRoomId(room.id);

          // if we have an aggregated timeseries we can find the corresponding source sensors
          if (
            asset.metadata?.source === "generated/5min" ||
            asset.metadata?.source === "generated/indoor_climate"
          ) {
            const links = await client.relationships
              .list({
                filter: {
                  targetExternalIds: [room.externalId!],
                },
              })
              .autoPagingToArray({ limit: Infinity });
            if (links.length > 0) {
              const related = await client.assets.retrieve(
                links.map((l) => ({
                  externalId: l.sourceExternalId.replace(/^TS_/, "AH_"),
                })),
                { ignoreUnknownIds: true },
              );
              const sensors = await getRoomSensorsForType(asset, related);
              setRelatedSensors(sensors);
            }
          }
        } else {
          setName(timeseries!.name);
        }
      } else {
        setName(timeseries!.name);
      }
    };

    if (!showAlarm && timeseries) {
      get();
    }
  }, [timeseries, client, showAlarm]);

  const items = [
    {
      label: (
        <Space>
          {t("common.timeseries-graph-modal.settings")}
          <Badge count={Object.keys(detailsErrors).length} size="small" />
        </Space>
      ),
      key: "info",
      children: (
        <DetailsPane
          showSettings={showSettings}
          timeseries={timeseries}
          alarmOwner={alarm?.owner}
          alarmName={alarm?.name}
          relatedSensors={relatedSensors}
          setAlarmName={
            alarm
              ? (name) => {
                  setAlarm({ ...alarm, name });
                  validateDetails();
                }
              : undefined
          }
          alarmDescription={alarm?.description}
          setAlarmDescription={
            alarm
              ? (description) => {
                  setAlarm({ ...alarm, description });
                }
              : undefined
          }
          overrideUnit={
            timeseries &&
            overrideUnits &&
            overrideUnits[timeseries.externalId!]?.unit
          }
          view={timeseriesInfo?.view}
          setView={
            setTimeseriesInfo
              ? (view: SensorInfo) => {
                  setTimeseriesInfo({ ...timeseriesInfo, view });
                }
              : undefined
          }
          errors={detailsErrors}
          buildingId={buildingId}
        />
      ),
    },
  ];

  if (showAlerts && setTimeseriesInfo) {
    items.push({
      label: (
        <span>{t("common.timeseries-graph-modal.monitor-fields.monitor")}</span>
      ),
      key: "monitor",
      children: (
        <MonitorPane
          timeseriesInfo={timeseriesInfo}
          setTimeseriesInfo={setTimeseriesInfo}
          timeseries={timeseries}
        />
      ),
    });
  }

  if (alarm) {
    items.push({
      label: (
        <Space data-testid="alarm-settings">
          {t("common.timeseries-graph-modal.alarm-fields.alarm")}
          <Badge count={Object.keys(alarmErrors).length} size="small" />
        </Space>
      ),
      key: "alarms",
      children: (
        <AlarmPane
          severity={alarm.severity}
          onChangeSeverity={(val) => {
            setAlarm({ ...alarm, severity: val });
          }}
          max={alarm.max}
          onChangeMax={
            ALARM_TYPE_CONFIG[alarm.type].showMax
              ? (val) => {
                  if (val === undefined) {
                    validateAlarm();
                  } else {
                    setAlarmErrors({});
                  }
                  setAlarm({ ...alarm, max: val });
                }
              : undefined
          }
          min={alarm.min}
          onChangeMin={
            ALARM_TYPE_CONFIG[alarm.type].showMin
              ? (val) => {
                  if (val === undefined) {
                    validateAlarm();
                  } else {
                    setAlarmErrors({});
                  }
                  setAlarm({ ...alarm, min: val });
                }
              : undefined
          }
          delay={alarm.delay || 0}
          onChangeDelay={(delay) => setAlarm({ ...alarm, delay })}
          comparisonTimeseriesMax={comparisonTimeseriesMax}
          comparisonTimeseriesMin={comparisonTimeseriesMin}
          onEditComparisonTimeseriesMax={onEditMaxTimeseries}
          onEditComparisonTimeseriesMin={onEditMinTimeseries}
          onChangeComparisonTimeseriesMax={(comparisonTimeseriesMax) =>
            setAlarm({
              ...alarm,
              compareWithTsMax: comparisonTimeseriesMax,
            })
          }
          onChangeComparisonTimeseriesMin={(comparisonTimeseriesMin) =>
            setAlarm({
              ...alarm,
              compareWithTsMin: comparisonTimeseriesMin,
            })
          }
          errors={alarmErrors}
          subscription={subscription}
          onChangeSubscription={setSubscription}
          interval={alarm.schedule?.interval}
          unit={
            (timeseries &&
              overrideUnits &&
              overrideUnits[timeseries.externalId!]?.unit) ||
            alarm.timeseries?.unit ||
            ""
          }
          maxTimeSinceLatestDatapoint={alarm.maxTimeSinceLatestDatapoint}
          onChangeMaxTimeSinceLatestDatapoint={(maxTimeSinceLatestDatapoint) =>
            setAlarm({ ...alarm, maxTimeSinceLatestDatapoint })
          }
          onChangeIncompleteSubscriber={handleChangeIncompleteSubscriber}
        />
      ),
    });
    items.push({
      label: (
        <span data-testid="alarm-schedule">
          {t("common.timeseries-graph-modal.plan.title")}
        </span>
      ),
      key: "plan",
      children: <SchedulePane alarm={alarm} onChange={setAlarm} />,
    });
    items.push({
      label: <span>{t("common.timeseries-graph-modal.deviation.title")}</span>,
      key: "events",
      children: (
        <div style={{ height: 460, overflow: "auto" }}>
          <AlarmEventsList
            alarm={alarm as any}
            showCreateNoteButton={showCreateNoteButtonInAlarmEventsList}
            hideParentModal={hide}
          />
        </div>
      ),
    });
  }

  if (showSetPoints && setPoints) {
    items.push({
      label: (
        <Space>
          {t("common.timeseries-graph-modal.setpoints.title")}
          <Badge count={Object.keys(setPointErrors).length} size="small" />
        </Space>
      ),
      key: "set_points",
      children: (
        <SetPointsPane
          setPoints={setPoints}
          errors={setPointErrors}
          setSetPoints={setSetPoints}
        />
      ),
    });
  }

  if (showDocuments && timeseries) {
    items.push({
      label: <span>{t("common.timeseries-graph-modal.documents.title")}</span>,
      key: "documents",
      children: <DocumentsPane timeseries={timeseries} />,
    });
  }

  const storeDescription = async () => {
    setEditDescription(false);
    const isTimeseriesDescriptionUpdated = (newDescription?: string) => {
      return newDescription !== timeseries?.description;
    };

    if (isTimeseriesDescriptionUpdated(newDescription)) {
      try {
        await updateTimeseries({
          external_id: timeseries!.externalId!,
          description: newDescription,
        });
        message.open({
          type: "success",
          content: t("common.timeseries-graph-modal.description-was-updated", {
            description: newDescription,
          }),
          duration: 7,
        });
      } catch (error) {
        setNewDescription(undefined);
        message.open({
          type: "error",
          content: t(
            "common.timeseries-graph-modal.can-not-update-description",
            {
              error: parseError(error),
            },
          ),
          duration: DEFAULT_MESSAGE_DURATION,
        });
        console.error(
          t("common.timeseries-graph-modal.can-not-update-description", {
            error: error as any,
          }),
        );
      }
    }
  };
  const canBeDeleted =
    user.isAdmin ||
    (currentAlarm.current && currentAlarm.current.owner === user.email);

  return (
    <GModal
      open
      width={1139}
      footer={null}
      onCancel={async () => {
        if (newDescription) {
          setNewDescription(undefined);
        }
        hide();
      }}
    >
      <Container className={(minimized && "minimized") || ""}>
        <GraphSection>
          <Header>
            {editDescription && (
              <DescriptionInput
                value={newDescription}
                allowClear
                autoFocus
                onChange={(event) => {
                  setNewDescription(event.target.value);
                }}
                onBlur={storeDescription}
                onPressEnter={storeDescription}
              />
            )}
            {!editDescription && editable ? (
              <Button
                type="link"
                style={{ display: "block", padding: 0, height: 40 }}
                onClick={() => {
                  setNewDescription(
                    newDescription || timeseries?.description || "",
                  );
                  setEditDescription(true);
                }}
              >
                <h1 style={{ margin: 0 }}>
                  {newDescription || timeseries?.description} <EditOutlined />
                </h1>
              </Button>
            ) : (
              !editDescription && (
                <h1 style={{ margin: 0 }}>{timeseries?.description}</h1>
              )
            )}
            {roomId && openRoomInfo ? (
              <Button
                type="link"
                style={{ padding: 0 }}
                onClick={() => {
                  if (openRoomInfo) {
                    if (!showBehindRoomInfo) {
                      hide();
                    }

                    openRoomInfo(roomId);
                  }
                }}
              >
                <Space>
                  {showExternalId ? timeseries?.externalId : name}
                  <ExternalLink height={16} width={16} />
                </Space>
              </Button>
            ) : (
              <span>{showExternalId ? timeseries?.externalId : name}</span>
            )}
          </Header>
          {start && end && timeseries && (
            <Graph
              timeseriesId={timeseriesInfo.id}
              min={
                timeseriesInfo?.min !== undefined
                  ? timeseriesInfo?.min
                  : typeof alarm?.min === "string"
                  ? Number(alarm?.min)
                  : alarm?.min
              }
              max={
                timeseriesInfo?.max !== undefined
                  ? timeseriesInfo?.max
                  : typeof alarm?.max === "string"
                  ? Number(alarm?.max)
                  : alarm?.max
              }
              minTimeseriesId={comparisonTimeseriesMin?.ids[0]}
              maxTimeseriesId={comparisonTimeseriesMax?.ids[0]}
              start={start}
              end={end}
              unit={
                (timeseries &&
                  overrideUnits &&
                  overrideUnits[timeseries.externalId!]?.unit) ||
                timeseries?.unit ||
                ""
              }
              timeseriesRoomId={timeseriesRoomId}
              yAxisMin={
                isStringNumeric(yAxisSettings?.min)
                  ? Number(yAxisSettings?.min)
                  : undefined
              }
              yAxisMax={
                isStringNumeric(yAxisSettings?.max)
                  ? Number(yAxisSettings?.max)
                  : undefined
              }
              stateDescription={getStateDescription(
                timeseries.metadata?.state_description,
              )}
            />
          )}
          <GraphController>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              {!yAxisSettings && (
                <Button
                  onClick={() => {
                    setYAxisSettings({});
                  }}
                >
                  {t("common.timeseries-graph-modal.fixed-y-axis")}
                </Button>
              )}
              {yAxisSettings && (
                <Form layout="inline">
                  <Form.Item
                    label={t("common.timeseries-graph-modal.y-axis")}
                    name="label"
                  />
                  <Form.Item
                    label={t("common.timeseries-graph-modal.min")}
                    name="min"
                  >
                    <Input
                      value={yAxisSettings.min}
                      size="small"
                      style={{ width: 100 }}
                      placeholder="0"
                      type="number"
                      onChange={(event) => {
                        setYAxisSettings({
                          ...yAxisSettings,
                          min: event.target.value,
                        });
                      }}
                    />
                  </Form.Item>
                  <Form.Item
                    label={t("common.timeseries-graph-modal.max")}
                    name="max"
                  >
                    <Input
                      value={yAxisSettings.max}
                      size="small"
                      style={{ width: 100 }}
                      placeholder="100"
                      type="number"
                      onChange={(event) => {
                        setYAxisSettings({
                          ...yAxisSettings,
                          max: event.target.value,
                        });
                      }}
                    />
                  </Form.Item>
                  <Button onClick={() => setYAxisSettings(null)}>
                    {t("common.timeseries-graph-modal.remove-fixed-y-axis")}
                  </Button>
                </Form>
              )}
              <GraphPeriod setStart={setStart} setEnd={setEnd} />
            </div>
          </GraphController>
        </GraphSection>
        <Details>
          <Minimize>
            <MinimizeButton
              icon={<ArrowRightOutlined />}
              type="link"
              onClick={() => setMinimized(!minimized)}
            />
          </Minimize>
          <DetailsContent>
            <div style={{ flex: 2 }}>
              <Tabs items={items} />
            </div>
            <Row justify="end">
              <Col>
                <Space direction="horizontal">
                  <Button onClick={() => hide()}>
                    {t("common.timeseries-graph-modal.cancel")}
                  </Button>
                  {deleteTimeseries && (
                    <Button
                      disabled={!canBeDeleted}
                      data-testid="deleteGraphModal"
                    >
                      <Popconfirm
                        title={t(
                          "common.timeseries-graph-modal.are-you-sure-to-delete",
                        )}
                        onConfirm={() => deleteTimeseries()}
                      >
                        {t("common.timeseries-graph-modal.delete")}
                      </Popconfirm>
                    </Button>
                  )}
                  <Button
                    type="primary"
                    onClick={submit}
                    data-testid="submitGraphModal"
                  >
                    {t("common.timeseries-graph-modal.ok")}
                  </Button>
                </Space>
              </Col>
            </Row>
          </DetailsContent>
        </Details>
      </Container>
    </GModal>
  );
};
